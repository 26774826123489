import { default as indexUMPrGhSAIgMeta } from "/home/yuyue/Documents/embbay/packages/web/pages/index.vue?macro=true";
import { default as indexCsb5RX5pLRMeta } from "/home/yuyue/Documents/embbay/packages/web/pages/public/docs/index.vue?macro=true";
import { default as docs72buby5FMVMeta } from "/home/yuyue/Documents/embbay/packages/web/pages/public/docs.vue?macro=true";
import { default as previewYPO7SqK8qBMeta } from "/home/yuyue/Documents/embbay/packages/web/pages/workshop/preview.vue?macro=true";
import { default as workshop6UvjmJlvzBMeta } from "/home/yuyue/Documents/embbay/packages/web/pages/workshop.vue?macro=true";
export default [
  {
    name: indexUMPrGhSAIgMeta?.name ?? "index",
    path: indexUMPrGhSAIgMeta?.path ?? "/",
    meta: indexUMPrGhSAIgMeta || {},
    alias: indexUMPrGhSAIgMeta?.alias || [],
    redirect: indexUMPrGhSAIgMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/embbay/packages/web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: docs72buby5FMVMeta?.path ?? "/public/docs",
    children: [
  {
    name: indexCsb5RX5pLRMeta?.name ?? "public-docs",
    path: indexCsb5RX5pLRMeta?.path ?? "",
    meta: indexCsb5RX5pLRMeta || {},
    alias: indexCsb5RX5pLRMeta?.alias || [],
    redirect: indexCsb5RX5pLRMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/embbay/packages/web/pages/public/docs/index.vue").then(m => m.default || m)
  }
],
    name: docs72buby5FMVMeta?.name ?? undefined,
    meta: docs72buby5FMVMeta || {},
    alias: docs72buby5FMVMeta?.alias || [],
    redirect: docs72buby5FMVMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/embbay/packages/web/pages/public/docs.vue").then(m => m.default || m)
  },
  {
    name: workshop6UvjmJlvzBMeta?.name ?? "workshop",
    path: workshop6UvjmJlvzBMeta?.path ?? "/workshop",
    children: [
  {
    name: previewYPO7SqK8qBMeta?.name ?? "workshop-preview",
    path: previewYPO7SqK8qBMeta?.path ?? "preview",
    meta: previewYPO7SqK8qBMeta || {},
    alias: previewYPO7SqK8qBMeta?.alias || [],
    redirect: previewYPO7SqK8qBMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/embbay/packages/web/pages/workshop/preview.vue").then(m => m.default || m)
  }
],
    meta: workshop6UvjmJlvzBMeta || {},
    alias: workshop6UvjmJlvzBMeta?.alias || [],
    redirect: workshop6UvjmJlvzBMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/embbay/packages/web/pages/workshop.vue").then(m => m.default || m)
  }
]